export const questionArr = [
  "什么是农业生态系统？",
  "如何进行农作物的产量评估？",
  "如何进行农作物的病虫害控制？",
  "什么是农业科技成果转型升级？",
  "如何进行农田灌溉？",
  "什么是农业机械化？",
  "如何进行种苗的选育和繁殖？",
  "什么是有机农业？",
  "如何进行农作物的施肥管理？",
  "如何进行农业废弃物的处理？",
  "什么是农业资源保护？",
  "如何进行农业食品安全管理？",
  "如何进行田间管理和作物管理？",
  "什么是粮食作物？",
  "如何进行果树的栽培和管理？",
  "如何进行蔬菜的栽培和管理？",
  "什么是畜牧业？",
  "如何进行草地的管理和利用？",
  "如何进行农业废弃物的资源化利用？",
  "什么是农村经济发展？",
  "如何进行农村土地利用规划？",
  "如何进行农业生产的管理和组织？",
  "什么是农村旅游？",
  "如何进行水稻的种植和管理？",
  "如何进行玉米的种植和管理？",
  "如何进行小麦的种植和管理？",
  "如何进行棉花的种植和管理？",
  "如何进行油菜的种植和管理？",
  "如何进行葡萄的种植和管理？",
  "如何进行苹果的种植和管理？",
  "如何进行柑橘的种植和管理？",
  "如何进行蔬菜的有机栽培？",
  "如何进行果树的有机栽培？",
  "如何进行水果的采摘和贮藏？",
  "如何进行农业机械设备的操作和维护？",
  "如何进行农业科技创新？",
  "如何进行农产品的质量检测和认证？",
  "如何进行农产品的包装和运输？",
  "如何进行农产品的营销和销售？",
  "什么是农村土地承包经营权？",
  "如何进行农村集体经济组织管理？",
  "如何进行土地资源的开发和利用？",
  "如何进行农田水利工程建设和管理？",
  "如何进行农村能源的开发和利用？",
  "如何进行农业生产的规划和布局？",
  "如何进行农村劳动力的培训和管理？",
  "如何进行农村金融服务和支持？",
  "什么是农村社会保障？",
  "如何进行农村环境保护？",
  "如何进行畜禽养殖的管理和监测？",
  "如何进行农产品的溯源管理？",
  "如何进行农产品的品牌建设和推广？",
  "如何进行农业投资和融资？",
  "如何进行农业保险和风险管理？",
  "什么是农业产业化？",
  "如何进行农业合作经济？",
  "如何进行农村电商发展？",
  "如何进行农村信息化建设？",
  "如何进行农村公共服务建设？",
  "如何进行农村人居环境整治？",
  "如何进行农产品的加工和深加工？",
  "如何进行农村人才队伍建设？",
  "如何进行农村社会治理？",
  "如何进行农村基础设施建设？",
  "如何进行农村住房建设？",
  "如何进行农村文化建设？",
  "如何进行农村旱涝保收和抗灾减灾？",
  "如何进行农业生态补偿和生态补偿标准制定？",
  "如何进行农村土地整治和集约利用？",
  "如何进行农村产业带和农村经济集聚发展？",
  "如何进行农村社会事业发展？",
  "如何进行农村产业转移和农民就业创业？",
  "如何进行农村公益事业和社会事业发展？",
  "如何进行农村科技服务和技术创新？",
  "如何进行农村人口政策和人口管理？",
  "如何进行农村政府的管理和服务？",
  "如何进行农村文化旅游和文化产业发展？",
  "如何进行农村生态文明建设？",
  "如何进行农村财政和税收管理？",
  "如何进行农村物流和交通运输建设？",
  "如何进行农村水土保持和防沙治沙？",
  "如何进行农村公共安全和治安维护？",
  "如何进行农村民主管理和乡村治理？",
  "如何进行农村社会救助和保障？",
  "如何进行农村宗教事务管理？",
  "如何进行农村国土资源管理和城乡规划？",
  "如何进行农村公共卫生和医疗卫生建设？",
  "如何进行农村教育和文化事业发展？",
  "如何进行农村体育和健身事业发展？",
  "如何进行农村科普和普及教育？",
  "如何进行农村法律服务和司法保障？",
  "如何进行农村国防和军事建设？",
  "如何进行农村民族事务和少数民族事业发展？",
  "如何进行农村统战和民族团结工作？",
  "如何进行农村外事和对外交流合作？",
  "如何进行农村国际合作和援助？",
  "如何进行农村公民道德和伦理建设？",
  "如何进行农村人权和法制建设？",
  "如何进行农村环保和生态保护？",
  "如何进行农村文化遗产和非物质文化遗产保护？",
  "如何进行农村社会保险和福利？",
  "如何进行农村社会组织和志愿服务？",
];
