<template>
  <div class="dialogue">
    <div class="chat-side">
      <div class="search-chat" v-if="hotList.length">
        选择以下话题，快速与我对话：
      </div>
      <div class="chat-list">
        <div
          class="chat-list-items"
          v-for="(t, index) in hotList"
          :key="index"
          @click="changeEntry(t)"
        >
          <span>{{ t }}</span>
        </div>
      </div>
    </div>
    <div class="chat-window">
      <div class="chat-main">
        <div class="chat-content">
          <div class="introduce">
            <div class="introduce-content">
              <span class="hello">你好， <br />我是农业知识专家</span>
              <span class="role">
                作为一个人工智能语言模型，我可以回答你的问题，为你提供有用信息，帮助你完成创作。
              </span>
              <div class="friendship-tips">
                <div class="friendship-tips-title">友情提示</div>
                <div class="friendship-tips-main">
                  <span>擅长中文，也会英文，其他语言正在学习；</span>
                  <span>请文明提问，我可能无法回答不合适的问题；</span>
                  <span>
                    我正在持续学习成长中，希望获得你的反馈，这将有助于我变得更好。
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="history-tips" @click="getHistory" v-if="isHistoryShow">
            <span>获取历史记录</span>
          </div>
          <div
            :class="[
              'chat-content-item',
              item.type == 'query' ? 'is-user-style' : '',
            ]"
            v-for="(item, index) in contentList"
            :key="index"
          >
            <div :class="['user-send-content']">{{ item.value }}</div>
          </div>
          <div v-show="newMsg" :class="['chat-content-item']">
            <div class="user-send-content">{{ newMsg }}</div>
          </div>
        </div>     <div class="send-module">
          <div
            class="send-window animated"
            :class="[isAnimate ? 'animate__headShake is-red' : '']"
          >
            <el-input
              class="send-textarea"
              type="textarea"
              placeholder="请输入问题"
              v-model.trim="sendInput"
              @keyup.enter.native="sendIssue"
            >
            </el-input>
            <div class="send-btn" @click="sendIssue">发送</div>
          </div>
        </div>
   
      </div>
    </div>
    <MyNav></MyNav>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { questionArr } from "@/utils/question.js";
export default {
  components: {
    MyNav: () => import("@/components/MyNav.vue"),
  },
  data() {
    return {
      isAnimate: false,
      isHistoryShow: true,
      wsUrl: "wss://data.darsing.net/chat/poe/",
      newMsg: "",
      webSock: null,
      scrollTimeout: null,
      sendInput: "",
      contentList: [],
      hotList: [],
    };
  },
  beforeDestroy() {
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
      this.scrollTimeout = null;
    }
    if (this.webSock) {
      this.webSock.close();
      this.webSock = null;
    }
  },
  watch: {
    newMsg: {
      handler() {
        this.scrollTimeout = setTimeout(() => {
          let divArr = document.querySelectorAll(".chat-content-item");
          let lastDiv = divArr[divArr.length - 1];
          if (lastDiv) {
            lastDiv.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });
          }
        }, 200);
      },
    },
  },
  mounted() {
    this.initWebSocket();
  },

  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
      userInfo: (state) => state.user.userInfo,
    }),
  },
  created() {
    sessionStorage.setItem("navLeft", "-100vh");
    this.getRandomArr(questionArr);
  },
  methods: {
    getHistory() {
      if (!this.webSock) {
        this.initWebSocket();
      }

      let obj = { type: "history" };
      this.websocketSend(JSON.stringify(obj));
      this.isHistoryShow = false;
      this.contentList = [];
      this.newMsg = "";
    },
    getArrRandomly(arr) {
      //打乱数组顺序
      let len = arr.length;
      for (let i = len - 1; i >= 0; i--) {
        let randomIndex = Math.floor(Math.random() * (i + 1));
        let itemIndex = arr[randomIndex];
        arr[randomIndex] = arr[i];
        arr[i] = itemIndex;
      }
      return arr;
    },
    getRandomArr(arr = [], num = 10) {
      const tmpArr = this.getArrRandomly(arr);
      for (let i = 0; i < num; i++) {
        this.hotList.push(tmpArr[i]);
      }
    },
    MakeDialogue() {
      if (this.webSock) {
        this.newMsg = "";
        this.contentList = [];
        this.webSock.close();
        this.webSock = null;
        this.initWebSocket();
      }
    },
    //初始化Websocket
    initWebSocket() {
      if (!!window.WebSocket && window.WebSocket.prototype.send) {
        this.webSock = new WebSocket(this.wsUrl);
        this.webSock.onmessage = this.websocketOnmessage;
        // this.webSock.onopen = this.websocketOnopen;
        this.webSock.onerror = this.websocketOnerror;
        this.webSock.onclose = this.websocketClose;
      } else {
        // this.$notify({
        //   title: "提示",
        //   message: "您的浏览器不支持Websocket通信协议",
        // });
      }
    },
    // websocketOnopen() {},
    websocketOnerror() {
      this.initWebSocket();
    },
    websocketOnmessage(e) {
      const redata = JSON.parse(e.data);

      if (Array.isArray(redata)) {
        redata.forEach((element) => {
          this.contentList.push(element);
        });
      } else {
        this.newMsg = this.newMsg + redata.value;
      }
    },
    websocketSend(Data) {
      this.webSock.send(Data);
    },
    websocketClose() {},
    changeEntry(item) {
      if (!this.webSock) {
        this.initWebSocket();
      }
      if (this.newMsg) {
        this.contentList.push({ value: this.newMsg, type: "answer" });
        this.newMsg = "";
      }
      let obj = { value: "", type: "query" };
      obj.value = item;
      this.contentList.push(obj);
      this.websocketSend(JSON.stringify(obj));
    },
    sendIssue() {
      if (!this.webSock) {
        this.initWebSocket();
      }

      if (!this.sendInput) {
        this.isAnimate = true;
        setTimeout(() => {
          this.isAnimate = false;
        }, 800);
        return;
      }
      if (this.newMsg) {
        this.contentList.push({ value: this.newMsg, type: "answer" });
        this.newMsg = "";
      }
      let obj = { value: "", type: "query" };
      obj.value = this.sendInput;
      this.contentList.push(obj);
      this.websocketSend(JSON.stringify(obj));
      this.sendInput = "";
    },
  },
};
</script>

<style lang="less" scoped>
.dialogue {
  width: 100%;
  min-height: 100vh;
  background-color: #071a2c;
  padding: 62px 48px 0;
  display: flex;
  .chat-side {
    width: 372px;
    height: 980px;
    background: #142d4c;
    padding: 40px 45px;
    display: flex;
    flex-direction: column;
    .new-chat {
      width: 167px;
      height: 48px;
      background: linear-gradient(0deg, #3579f6, #3eb8e5);
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 60px 0;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
      img {
        width: 35px;
        height: 34px;
        margin-right: 13px;
      }
      span {
        width: 71px;
        height: 17px;
        font-size: 16px;
        color: #fff;
      }
    }
    .search-chat {
      margin-bottom: 30px;

      font-size: 18px;
      font-weight: 400;
      color: #fff;
      padding: 0 0 0 15px;
      position: relative;
      &::before {
        content: "";
        background-color: #fff;
        width: 4px;
        height: 18px;
        border-radius: 2px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
      }
    }
    .chat-list {
      width: 100%;
      height: 83%;
      overflow-y: hidden;
      display: flex;
      flex-direction: column;
      .chat-list-items {
        width: 100%;
        min-height: 32px;
        background: #173355;
        border: 1px solid #1b3e68;
        border-radius: 2px;
        display: flex;
        align-items: center;
        padding: 6px 15px;
        margin-bottom: 22px;
        font-size: 12px;
        color: #fff;
        cursor: pointer;
        &:hover {
          color: #3e90e5;
        }
        .icon {
          width: 16px;
          height: 16px;
          margin-right: 13px;
        }
        span {
          line-height: 24px;
        }
      }
    }
  }
  .chat-window {
    flex: 1;
    height: 980px;
    background: #0e2138;
    display: flex;
    justify-content: center;
    padding: 34px 0;
    .chat-main {
      width: 970px;
      height: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      .chat-content {
        width: 100%;
        height: 740px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 0px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 0px;
        }

        .chat-content-item,
        .introduce {
          display: flex;
          margin-bottom: 20px;
          .chat-head {
            width: 47px;
            height: 47px;
            margin-right: 21px;
          }
          .introduce-content {
            width: 970px;
            height: 307px;
            background: rgba(34, 77, 127, 0.7);
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            color: #fff;
            padding: 20px 29px;
            .hello {
              font-size: 24px;
              font-weight: bold;
              margin-bottom: 21px;
              line-height: 34px;
            }
            .role {
              font-size: 12px;
              margin-bottom: 19px;
            }
            .friendship-tips {
              width: 100%;
              height: 144px;
              background: #193b61;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              padding: 21px;
              .friendship-tips-title {
                font-size: 16px;
                font-weight: bold;
                color: #ffd95e;
              }
              .friendship-tips-main {
                height: calc(100% - 26px);
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                span {
                  font-size: 12px;
                  color: #fff;
                }
              }
            }
          }
        }
        .chat-content-item {
          width: 100%;
          font-size: 14px;
          color: #fff;
          display: flex;
          .user-name {
            margin-right: 21px;
          }
          .user-send-content {
            padding: 14px 20px;
            border-radius: 10px;
            background-color: rgba(34, 77, 127, 0.7);
            line-height: 26px;
          }
          &.is-user-style {
            flex-direction: row-reverse;

            .user-send-content {
              color: #193b61;
              background-color: #fff;
            }
          }
        }
        .history-tips {
          width: 100%;
          font-size: 12px;
          text-align: center;
          color: #ccc;
          cursor: pointer;
          span {
            position: relative;

            &::before {
              content: "";
              width: 20px;
              height: 1px;
              background-color: #ccc;
              position: absolute;
              top: 50%;
              left: -30px;
              transform: translateY(-50%);
            }
            &::after {
              content: "";
              width: 20px;
              height: 1px;
              background-color: #ccc;
              position: absolute;
              top: 50%;
              right: -30px;
              transform: translateY(-50%);
            }
          }
        }
      }
      .send-module {
        width: 100%;
        display: flex;
        background: #0e2138;
        flex-direction: column;
        position: absolute;
        padding: 8px 0;
        bottom: 28px;
        .tips {
          font-size: 14px;
          color: #fff;
          margin-bottom: 17px;
        }
        .hot-entry {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 30px;
          .entry-items {
            min-height: 40px;
            background: rgba(34, 77, 127, 0.7);
            border-radius: 5px;
            padding: 15px 25px;
            font-size: 12px;
            color: #fff;
            margin: 0 11px 12px 0;
            cursor: pointer;
            &:hover {
              color: #3e90e5;
            }
          }
        }
        /deep/.send-window {
          position: relative;

          .send-textarea {
            .el-textarea__inner {
              width: 100%;
              height: 112px;
              background: rgba(34, 77, 127, 0.7);
              border-radius: 10px;
              padding: 21px 23px;
              font-size: 14px;
              color: #fff;
              resize: none;
              border: 1px solid rgba(34, 77, 127, 0.7);
              &::-webkit-scrollbar {
                width: 0px;
              }

              &::input-placeholder {
                color: #fff !important;
              }
              &::-webkit-input-placeholder {
                color: #fff !important;
              }
            }
          }
          .send-btn {
            width: 96px;
            height: 40px;
            background: linear-gradient(0deg, #3579f6, #3eb8e5);
            border-radius: 20px;
            font-size: 14px;
            color: #fff;
            text-align: center;
            line-height: 40px;
            position: absolute;
            bottom: 17px;
            right: 32px;
            cursor: pointer;
            &:hover {
              opacity: 0.8;
            }
          }
          &.is-red {
            .send-textarea {
              .el-textarea__inner {
                border-color: #f56c6c;
              }
            }
          }
        }
      }
    }
  }
}
</style>
